.your_choice_container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.your_choice_container_row {
    display: flex;
    justify-content: space-around;
}

.your_choice_container_row_image {
    margin: 0rem auto;
}

.your_choice_container img {
    width: 25rem;
    height: 25rem;

    margin: 0rem auto;
}

.your_choice_container_row_body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 60%;
    margin: 0rem auto;
}

.your_choice_container_row_body p {
    font-size: medium;
    text-align: justify;
}

.your_choice_container_row_body button {
    width: 10rem;
}

@media screen and (max-width: 650px) {
    .your_choice_container {
        height: fit-content;
        display: block;

        padding: 5rem 0rem;
    }

    .your_choice_container_row {
        display: block;
    }

    .your_choice_container_row_image {
        text-align: center;
    }

    .your_choice_container img {
        width: 15rem;
        height: 15rem;

        margin: 3rem 0rem;
    }
}
