.homepage_partners_card {
    width: 30%;

    margin: 3rem auto;
}

.homepage_partners_card_image {
    background-color: var(--color-lightWhite);
    border-radius: 10px;
}

.homepage_partners_card_image img{
    padding: 2rem;
    width: 20rem;
    height: 15rem;

    margin: 0rem auto;
}

.homepage_partners_card_body h3{
    color: var(--color-lightWhite);
    text-align: center;

    margin-top: 1rem;
}

@media screen and (max-width: 650px) {
    .homepage_partners_card {
        width: 90%;
    
        margin: 3rem auto;
    }
}