.homepage_header_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    /* background: linear-gradient(rgba(40, 120, 235, .9), rgba(40, 120, 235, .9)),  no-repeat center center; */
    background: linear-gradient(rgba(34, 34, 34,.9),rgba(34, 34, 34,.9)),
    url(../../../assets/building.jpg),
    no-repeat center center;
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.homepage_header_container::after {
    bottom: 0;
    background: url(../../../assets/overlay-bottom.png) bottom center no-repeat;
    background-size: auto;
    background-size: contain;
    position: absolute;
    content: "";
    width: 100%;
    height: 85px;
    left: 0;
    z-index: 1;
}

.homepage_header_content {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.homepage_header_content h1 {
    font-weight: 900;
    font-size: 5rem;
    line-height: 10rem;

    /* Gradient Text Color */
    background: var(--gradient-white-text);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.homepage_header_content p {
    font-size: x-large;
    color: var(--color-lightWhite);
}

.homepage_header_image {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.homepage_header_image img {
    width: 25rem;
    height: 35rem;
    
}

@media screen and (max-width: 650px) {
    .homepage_header_container {
        display: block;
        padding: 1rem;
        padding-top: 7rem;
        height: fit-content;
        padding-bottom: 10rem;
    }

    .homepage_header_container::after {
       display: none;
    }

    .homepage_header_image {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .homepage_header_image img {
        width: 20rem;
        height: 27rem;
        
    }
}

