@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
    --font-family: 'Manrope', sans-serif;

    --gradient-text: linear-gradient(90deg, #047CC1 0%, #222222 68%);
    --gradient-white-text: linear-gradient(90deg, #047cc1 0%, #fafafa 68%);
    --linear-background: linear-gradient(90deg, #222222 32%, #047cc1 100%);

    --shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    --invertShadow: inset 0 4px 8px 0 #00000033, inset 0 6px 20px 0 #00000030;
    --whiteShadow: 0 4px 8px 0 #fafafa, 0 6px 20px 0 #fafafa;

    
    --color-footer: #031B34;
    --color-blog: #042c54;
    --color-text: #81AFDD;
    --color-subtext: #FF8A71;

    --color-greyBlack: #222222;
    --color-mainBlue: #047cc1;
    --color-lightWhite: #fafafa;
    --color-totalWhite: #ffffff;
}


* {
    font-family: var(--font-family);
}

/* Buttons */

.main-btn {
    background: var(--color-greyBlack);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    color: var(--color-totalWhite);
    border: 1px solid var(--color-greyBlack);
    font-family: var(--font-family);
    cursor: pointer;
    outline: 1px solid var(--color-totalWhite);
    margin-top: 2rem;

    transition: all .5s ease;
}

.main-btn:hover {
    background: var(--color-totalWhite);
    color: var(--color-greyBlack);
    transform: scale(1.1);
}

.secondary-btn {
    background: var(--color-greyBlack);
    color: var(--color-totalWhite);

    border: 1px solid var(--color-totalWhite);
    border-radius: 5px;
    
    padding: 0.5rem 1rem;
    
    cursor: pointer;

    transition: all .5s ease;
}

.secondary-btn:hover {
    background: var(--color-mainBlue);
    transform: scale(1.1);
}