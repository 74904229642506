.qr_codes_header {
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.qr_codes_header h1,
.qr_codes_header h3 {
    text-align: center;
}

.qr_codes_header_row {
    display: flex;
    justify-content: space-around;

    width: 80%;
    margin: 0rem auto;
}

.qr_codes_header_row_body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-right: 2rem;
}

.qr_codes_header_row_body p {
    text-align: justify;
    font-size: medium;
}

.qr_codes_header_row img {
    width: 20rem;
}

@media screen and (max-width: 650px) {
    .qr_codes_header {
        height: fit-content;
        padding-top: 7rem;
        padding-bottom: 7rem;
        display: block;
    }

    .qr_codes_header_row {
        display: block;    
        width: 80%;
    }

    .qr_codes_header_row img {
        margin: 2rem auto;
    }
}