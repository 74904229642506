.web_development_header_container {
    padding-top: 7rem;
    height: 100vh;
}

.web_development_header_container h1 {
    text-align: center;
    font-size: xx-large;
}

.web_development_header_container h3 {
    text-align: center;
}

.web_development_header_body {
    display: flex;
    justify-content: space-around;
    width: 70%;

    margin: 2rem auto;
    padding-top: 2rem;
}

.web_development_header_body_paragraphs {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.web_development_header_body_paragraphs p {
    font-size: medium;
    text-align: justify;

    padding-right: 2rem;
}

.web_development_header_body_paragraphs button {
    width: 10rem;
}

.web_development_header_body img{
    width: 20rem;
    height: 20rem;
}

.web_development_header_body_paragraphs_bottom {
    width: 70%;
    margin: 3rem auto;
    font-size: medium;
    text-align: justify;
}

@media screen and (max-width: 650px) {

    .web_development_header_container {
        height: fit-content;
        padding-bottom: 3rem;

    }

    .web_development_header_body {
        display: block;
    }

    .web_development_header_body img{
        width: 15rem;
        height: 15rem;

        margin: 2rem auto;
    }

    .web_development_header_body_paragraphs p {
        padding-right: 0rem;
    }
}