.customNavbar {
    display: flex;
    flex-direction: row;
    justify-content: center;

    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;

    z-index: 100;
}

.menu_logo {
    width: 10rem;
}

.menu_logo img {
    width: 100%;
}