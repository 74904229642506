.services_card_container {
    width: 70%;
    text-align: center;
    margin: 5rem auto;
    padding: 2rem;

    transition: all .5s ease;
}

.services_card_container:hover .services_card_body p {
    color: var(--color-lightWhite);
}
.services_card_container:hover {
    background-color: var(--color-greyBlack);
    border-radius: 10px;
    
}

.services_card_img_container{
    width: 10rem;
    height: 10rem;

    border: 1px solid #047CC1;
    border-radius: 100%;

    margin: 2rem auto;
    padding: 2rem;

    text-align: center;
}

.services_card_img_container img{
    width: 100%;
}

.services_card_body h1 {
    color: var(--color-mainBlue);
}

.services_card_body button {
    width: 10rem;
    margin: 1rem auto;
}