.homepage_partners_container {
    padding-bottom: 3rem;
}

.partners_select_cards_row {
    display: flex;
    justify-content: center;
}

.partners_select_cards_single_card {
    width: 20%;

    text-align: center;
}

.partners_select_cards_single_card:hover {
    cursor: pointer;
}

.partners_select_cards_single_card img {
    width: 15rem;
    height: 10rem;

    border-radius: 100%;

    padding: 1rem;
}

.partners_select_cards_single_card h3 {
    color: var(--color-lightWhite);
}

@media screen and (max-width: 650px) {
    .partners_select_cards_row {
        display: block;
        text-align: center;
    }

    .partners_select_cards_single_card {
        width: 80%;
    
        text-align: center;
        margin: 2rem auto;
    }
}