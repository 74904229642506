.homepage_values_container {
    display: flex;
    justify-content: center;
}

.homepage_values_single_value {
    width: 30%;
    display: flex;

    height: 30rem;
}


.homepage_values_single_value_image {
    width: 50%;
    display: flex;
    flex-direction: column;

    justify-content: center;
}

.homepage_values_single_value_image img {
    width: 100%; 
}

.homepage_values_single_value_body {
    display: flex;
    flex-direction: column;

    justify-content: center;

    padding-left: 1rem;
}

@media screen and (max-width: 650px) {
    .homepage_values_container {
        display: block;
    }

    .homepage_values_single_value {
        width: 80%;
        display: block;
        margin: 2rem auto;
        height: fit-content;
    }

    .homepage_values_single_value_image {
        width: 100%;
    }

    .homepage_values_single_value_image img {
        margin: 2rem auto;
        width: 10rem;
    }
}