.quality_container {
    display: flex;
    justify-content: space-around;
    padding: 6rem 6rem;

    background-color: var(--color-greyBlack);
}

.quality_container_img {
    text-align: center;
    width: 30%;
}


.quality_container_img img {
    width: 15rem;
}

.quality_container_body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 60%;
}

.quality_container_body h1 {
    color: var(--color-lightWhite);
}

.quality_container_body p {
    text-align: justify;
    color: var(--color-lightWhite);
}

.quality_container_body button {
    width: 10rem;
}

@media screen and (max-width: 650px) {
    .quality_container {
        display: block;
        padding: 6rem 2rem;
    }

    .quality_container_img {
        text-align: center;
        width: 100%;
    }

    .quality_container_body {    
        width: 100%;
    }
    
}