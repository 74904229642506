.footer_container {
    padding: 2rem 0rem;
    color: var(--color-lightWhite);
    background-color: var(--color-greyBlack);
    text-align: center;
}

.footer_social_row {
    display: flex;
    justify-content: space-around;

    width: 50%;
    margin: .5rem auto;
}

.footer_social_col {
    width: 3rem;
}

.footer_social_col img{
    width: 100%
}

@media screen and (max-width: 650px) {
    .footer_social_row {
        width: 80%;
    }
}