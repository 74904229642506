.contact-us-container {
    background-image: url("../../assets/building.jpg");
    background-color: rgba(34, 34, 34, 1);
    /* Tint color */
    background-blend-mode: multiply;
    background-attachment: fixed;
    color: #fff;
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
    font-family: var(--font-family);

}

.contact-us-row h1,
.contact-us-row h3 {
    color: var(--color-lightWhite);
}

.contact-us-form {
    background: rgba(34, 34, 34, 0.9);
    width: 50%;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
    outline: 1px solid #fff;
    border-radius: 5px;
}

.contact-us-form input,
.contact-us-form textarea {
    background: rgba(34, 34, 34, 0.9);
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    color: #fff;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
    width: 50%;
}

.contact-us-form input:focus,
.contact-us-form textarea:focus {
    border-bottom: 2px solid #fff;
    font-style: italic;
}

.form-send-button button {
    background: rgba(34, 34, 34, 1);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    color: #fff;
    border: none;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    outline: 1px solid #fff;
    margin-top: 0.5rem;

    transition: all 1s ease;
}

.form-send-button button:hover {
    background: #fff;
    color: #222222;
    outline: 1px solid #222222;
}


.recaptcha {
    padding-top: 3rem;
}

.recaptcha-box>div>div {
    margin-left: auto;
    margin-right: auto;
}

.recaptcha-box>div>div>div>iframe {
    width: 100% !important;

}


.recaptcha-box {
    margin-left: auto;
    margin-right: auto;
}

#recaptcha-verification-text,
#valid-email-verification-text,
#fill-fields-verification-text,
#invalid-recaptcha {
    color: #d9534f;
    display: none;
    font-size: 14px;
    font-style: italic;
}

#email-sent {
    color: #5cb85c;
    display: none;
    font-size: 14px;
    font-style: italic;
}


@media screen and (max-width: 700px) {

    .contact-us-form {
        width: 80%;
    }

    .contact-us-form input,
    .contact-us-form textarea {
        width: 80%;
    }


    .recaptcha-box>div>div>div {
        width: 80%;
    }

    .recaptcha-box>div>div>div>iframe {
        width: 100% !important;
        margin: 0rem auto;
    }

    .rc-anchor-light.rc-anchor-normal {
        border: 1px solid #d3d3d3 !important;
        display: flex !important;
        flex-direction: column !important;
        height: fit-content !important;
    }

}