.after_release_container {
    background-color: var(--color-greyBlack);
    color: var(--color-lightWhite);

    padding: 6rem;
}

.after_release_container h1 {
    color: var(--color-lightWhite);
}

.after_release_row {
    display: flex;
    justify-content: space-around;
}

.after_release_row_card {
    width: 40%;
    margin: 3rem auto;
}

.after_release_title_and_image {
    text-align: center;
}

.after_release_title_and_image h2 {
    color: var(--color-lightWhite);
}

@media screen and (max-width: 650px) {
    .after_release_container {
        padding: 3rem;
    }

    .after_release_row {
        display: block;
    }

    .after_release_row_card {
        width: 80%;
    }
}