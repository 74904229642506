.spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 2rem;

    z-index: 1;
}

.spinner-container .spinner {
    color: var(--darkGrey);

    padding: 2rem;
    
    width: 100px;
    height: 100px;
}