.homepage_services_container::after {
    background: url(../../../assets/overlay-bottom.png) bottom center no-repeat;
    background-size: auto;
    background-size: contain;
    position: absolute;
    content: "";
    width: 100%;
    height: 85px;
    left: 0;
    z-index: 1;
    transform: rotate(180deg);
}

.homepage_services_container h1{
    text-align: center;
}

.homepage_services_row {
    display: flex;
    justify-content: space-around;
    margin: 3rem auto;
}

.homepage_services_col {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.homepage_services_col:nth-child(odd) {
    justify-content: center;
}

@media screen and (max-width: 650px) {
    .homepage_services_row {
        display: block;
    }

    .homepage_services_col {
        width: 100%;
    }
}