.about_us_header_container {
    padding-top: 5rem;
    text-align: center;

    height: 83vh;
}

.about_us_header_container img {
    width: 15rem;
    height: 20rem;

    margin: 0rem auto;
}

.about_us_header_container p {
    width: 60%;
    margin: 0rem auto;

    text-align: justify;
}

@media screen and (max-width: 650px) {
    .about_us_header_container {
        height: fit-content;
        padding-bottom: 3rem;
    }
}