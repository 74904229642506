.user_centered_container {
    padding: 0rem 6rem;
    padding-top: 2rem;
}

.user_centered_container p {
    text-align: justify;
}

@media screen and (max-width: 650px) {
    .user_centered_container {
        padding: 0rem 3rem;
        padding-top: 2rem;
    }
}