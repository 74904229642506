.procedure_image_left_container {
    display: flex;
    justify-content: space-around;

    width: 50%;
    margin: 2rem auto;
}

.procedure_image_left_body {
    padding-left: 2rem;
}

.procedure_image_left_body h2 {
    color: var(--color-mainBlue);
}

.procedure_image_left_body p {
    text-align: justify;
}

@media screen and (max-width: 650px) {
    .procedure_image_left_container {
        display: block;
        justify-content: space-around;
    
        width: 80%;
        margin: 2rem auto;

        text-align: center;
    }
}