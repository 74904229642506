.mobile_apps_header_container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-greyBlack);
    color: var(--color-lightWhite);
}

.mobile_apps_header_container h1,
.mobile_apps_header_container h3 {
    text-align: center;
    color: var(--color-lightWhite);
}

.mobile_apps_header_row {
    display: flex;
    justify-content: space-around;

    padding: 2rem;
}

.mobile_apps_header_body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 80%;
    margin: 0rem auto;
}

.mobile_apps_header_body button {
    width: 10rem;
}


.mobile_apps_header_img img {
    width: 15rem;
    height: 15rem;

    margin: 0rem auto;
}

@media screen and (max-width: 650px) {
    .mobile_apps_header_container {
        height: fit-content;
        display: block;
        
        padding-top: 7rem;
    }

    .mobile_apps_header_row {
        display: block;
    
        padding: 2rem;
    }

    .mobile_apps_header_img {
        width: 100%;
        text-align: center;

        padding: 5rem 0rem;
    }
    
}