.ecommerce_header_container {
    background-color: var(--color-greyBlack);
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ecommerce_header_container h1,
.ecommerce_header_container h3 {
    text-align: center;
    color: var(--color-lightWhite);
}

.ecommerce_header_body {
    display: flex;
    justify-content: space-around;
}

.ecommerce_header_body_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-lightWhite);

    width: 60%;
    margin: 0rem auto;
}

.ecommerce_header_body_text h2 {
    color: var(--color-lightWhite);
}

.ecommerce_header_body_text p {
    font-size: medium;
}

.ecommerce_header_body img {
    width: 25rem;
    height: 25rem;

    margin: 0rem auto;
}

@media screen and (max-width: 650px) {
    .ecommerce_header_container {
        padding-top: 7rem;
        height: fit-content;
    
        display: block;
    }

    .ecommerce_header_body {
        display: block;
        padding-top: 5rem;

        text-align: center;
    }

    .ecommerce_header_body img {
        width: 15rem;
        height: 15rem;

        margin: 2rem auto;
    }

    .ecommerce_header_body_text p {
        text-align: justify;
    }
}
