.web_package_container {
    background: #222222;
    color: #fff;
    padding-top: 5rem;
    padding-bottom: 5rem;  
}

.web_package_row {
    display: flex;
    justify-content: center;
}

.web_package_card{
    width: 28%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 1rem;
}

.web_package_card_img{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.web_package_card_img img {
    width: 100%;
}
.web_package_card h3{
    text-align: center;
    padding-bottom: 0.5rem;
    color: var(--color-lightWhite);
}

.web_package_card p{
    text-align: justify;
    color: var(--color-lightWhite);
}

@media screen and (max-width: 650px) {
    .web_package_row {
        display: block;
    }
    
    .web_package_card{
        width: 80%;
        margin: 2rem auto;
    }
}