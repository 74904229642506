.qr_package_container {
    background-color: var(--color-greyBlack);
    padding: 8rem 3rem;
    padding-top: 3rem;
}

.qr_package_container_row {
    display: flex;
    justify-content: space-around;

    margin: 3rem auto;
}

.qr_package_single_package {
    width: 30%;
    text-align: center;
}

.qr_package_single_package img{
    width: 10rem;
    margin-bottom: 2rem;
}

.qr_package_single_package h1,
.qr_package_single_package p {
    color: var(--color-lightWhite);
}

@media screen and (max-width: 650px) {
    .qr_package_container_row {
        display: block;    
        margin: 3rem auto;
    }

    .qr_package_single_package {
        width: 80%;
        margin: 2rem auto;
    }

    .qr_package_container {
        height: fit-content;
        padding-bottom: 20rem;
    }
}