.homepage_service_card_container {
    padding: 1rem;
    
    box-shadow: var(--shadow);
    border-radius: 10px;

    margin: 1rem auto;

    width: 70%;

    transition: all .5s ease;
}

.homepage_service_card_container:hover {
    cursor: pointer;
    scale: 1.1;
}

.homepage_service_card_empty_container {
    height: fit-content;
}

.homepage_service_card_img{
    text-align: center;

    width: 10rem;
    margin: 1rem auto;

}

.homepage_service_card_img img{
    width: 100%;
    padding: 1rem;

}

.homepage_service_card_title {
    text-align: center;
}

.homepage_service_card_body {
    text-align: justify;
}

@media screen and (max-width: 650px) {
    .homepage_service_card_container {
       width: 90%;
    }
}